import { createI18n } from 'vue-i18n';
import Store from '@/store';
import en from '@/languages/english.json';
//import ko from '@/languages/korean.json';
import ja from '@/languages/japanese.json';
import zh from '@/languages/chinese.json';

//const messages = { en, ko, ja, zh };
const messages = { en, ja, zh };

const getLocaleFromUrl = () => {
  const userLang = navigator.language.split('-')[0].toLocaleLowerCase();
  const pathLang = window.location.pathname.split('/')[1];
  const keys = Object.keys(messages).map((key) => key.toLowerCase());
  if (keys.includes(pathLang)) {
    Store.commit('setLanguage', pathLang);
    return pathLang;
  }
  if (keys.includes(userLang)) {
    Store.commit('setLanguage', userLang);
    return userLang;
  }
  return 'en';
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const i18n = createI18n({
  locale: getLocaleFromUrl(),
  fallbackLocale: 'en',
  messages,
});

export default i18n;
