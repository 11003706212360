import { createRouter, createWebHistory, RouteRecordRaw, RouterScrollBehavior } from 'vue-router';
import Store from '@/store';
import AuthenticationAPI from '@/business/AuthenticationAPI';

const addLangPath = (routes: Array<RouteRecordRaw>): Array<RouteRecordRaw> => {
  for (const route of routes) {
    if (route.children) {
      addLangPath(route.children);
    } else {
      route.path = `/:lang([a-z]{2})${route.path}`;
    }
  }
  return routes;
};

const routes: Array<RouteRecordRaw> = addLangPath([
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/common/HomeView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/common/LoginView.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/common/LogoutView.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/common/SignUpView.vue'),
  },
  {
    path: '/joined',
    name: 'joined',
    component: () => import('@/views/common/JoinedView.vue'),
  },
  {
    path: '/tos',
    name: 'tos',
    component: () => import('@/views/common/TOSView.vue'),
  },
  {
    path: '/mypage',
    name: 'mypage',
    redirect: { name: 'mypage.notification' },
    component: () => import('@/views/mypage/SideMenuView.vue'),
    children: [
      {
        path: '/mypage/notification',
        name: 'mypage.notification',
        component: () => import('@/views/mypage/list/NotificationListView.vue'),
      },
      {
        path: '/mypage/myinfo',
        name: 'mypage.myinfo',
        component: () => import('@/views/mypage/detail/InformationView.vue'),
      },
      {
        path: '/mypage/refill',
        name: 'mypage.refill',
        component: () => import('@/views/mypage/list/RefillListView.vue'),
      },
      {
        path: '/mypage/refilled',
        name: 'mypage.refilled',
        component: () => import('@/views/mypage/list/RefilledListView.vue'),
      },
      {
        path: '/mypage/request',
        name: 'mypage.request',
        component: () => import('@/views/mypage/list/RequestListView.vue'),
      },
      {
        path: '/mypage/request/:seq',
        name: 'mypage.request.detail',
        component: () => import('@/views/mypage/detail/RequestDetailView.vue'),
      },
      {
        path: '/mypage/requested',
        name: 'mypage.requested',
        component: () => import('@/views/mypage/list/RequestedListView.vue'),
      },
      {
        path: '/mypage/requested/:seq',
        name: 'mypage.requested.detail',
        component: () => import('@/views/mypage/detail/RequestedDetailView.vue'),
      },
      {
        path: '/mypage/inquiry',
        name: 'mypage.inquiry',
        component: () => import('@/views/mypage/list/InquiryListView.vue'),
      },
      {
        path: '/mypage/wish',
        name: 'mypage.wish',
        component: () => import('@/views/mypage/list/WishListView.vue'),
      },
      {
        path: '/mypage/having',
        name: 'mypage.having',
        component: () => import('@/views/mypage/list/HavingListView.vue'),
      },
      {
        path: '/mypage/point',
        name: 'mypage.point',
        component: () => import('@/views/mypage/list/PointListView.vue'),
      },
    ],
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('@/views/service/NoticeListView.vue'),
  },
  {
    path: '/notice/:seq',
    name: 'notice.detail',
    component: () => import('@/views/service/NoticeDetailView.vue'),
  },
  {
    path: '/inquiry',
    name: 'inquiry',
    component: () => import('@/views/service/InquiryWriteView.vue'),
  },
  {
    path: '/inquiry/complete',
    name: 'inquiry.complete',
    component: () => import('@/views/service/InquiryCompleteView.vue'),
  },
  {
    path: '/advsearch',
    name: 'advsearch',
    component: () => import('@/views/service/AdvancedSearchView.vue'),
  },
  {
    path: '/contents',
    name: 'contents',
    component: () => import('@/views/service/ContentsListView.vue'),
  },
  {
    path: '/contents/:seq',
    name: 'contents.detail',
    component: () => import('@/views/service/ContentsDetailView.vue'),
  },
  {
    path: '/actress',
    name: 'actress',
    redirect: { name: 'home' },
  },
  {
    path: '/actress/:seq',
    name: 'actress.detail',
    component: () => import('@/views/service/ActressDetailView.vue'),
  },
  {
    path: '/public',
    name: 'public',
    redirect: { name: 'public.contents' },
    children: [
      {
        path: '/public/contents',
        name: 'public.contents',
        component: () => import('@/views/public/ContentsListView.vue'),
      },
      {
        path: '/public/contents/:seq',
        name: 'public.contents.detail',
        component: () => import('@/views/public/ContentsDetailView.vue'),
      },
    ],
  },
]);

const history = createWebHistory(process.env.BASE_URL);
const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 };
  }
};
const router = createRouter({ history, routes, scrollBehavior });
router.beforeEach(async (to, from, next) => {
  if (!to.params.lang) {
    const lang = Store.getters.language;
    return next(`/${lang}${to.fullPath}`);
  }

  let name = (to.name as string) ?? '';
  if (name.split('.').length > 1) {
    name = name.split('.')[0];
  }

  const publicPage = ['home', 'logout'];
  if (publicPage.includes(name)) {
    return next();
  }

  const removeToken = ['login', 'signup', 'joined', 'tos', 'public'];
  if (removeToken.includes(name)) {
    Store.commit('setAccessToken', '');
    Store.commit('setRefreshToken', '');
    return next();
  }

  try {
    const tokenData = await AuthenticationAPI.refreshToken(Store.getters.refreshToken);
    if (tokenData.access !== Store.getters.accessToken) {
      Store.commit('setAccessToken', tokenData.access ?? '');
      Store.commit('setRefreshToken', tokenData.refresh ?? '');
    }
  } catch (error) {
    Store.commit('setAccessToken', '');
    Store.commit('setRefreshToken', '');
    return next('/');
  }
  return next();
});

export default router;
